.login {
    position: absolute;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
    padding: 20px;
    box-sizing: border-box;
}